import { render, staticRenderFns } from "./sayfalama.vue?vue&type=template&id=44798e23&scoped=true&"
import script from "./sayfalama.vue?vue&type=script&lang=js&"
export * from "./sayfalama.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44798e23",
  null
  
)

export default component.exports