<template>
  <div class="sidebar-detached sidebar-left mt-2">
    <div class="sidebar">
      <div class="sidebar-shop" style="z-index: 999" :class="{ show: mqShallShowLeftSidebar }">
        <b-card class="rounded-0 mt-5" title="Filtrele">
          <b-form @submit.stop.prevent="$emit('filtreUygula')">
            <div class="product-categories">
              <h6 class="filter-title">Ürün Adına göre</h6>
              <b-form-input v-model="filtrele.arama" class="rounded-0" placeholder="Arama..." />
            </div>

            <div class="product-categories">
              <h6 class="filter-title">Kategoriler</h6>
              <treeselect
                v-model="filtrele.kategori"
                :normalizer="normalizer"
                :multiple="true"
                :always-open="false"
                :open-on-focus="true"
                :flat="true"
                :options="filterOptions.kategoriler"
              />
            </div>

            <!-- Brands -->
            <div class="brands">
              <h6 class="filter-title">Bölgeler</h6>
              <treeselect
                v-model="filtrele.bolge"
                :normalizer="normalizer"
                :multiple="true"
                :always-open="false"
                :open-on-focus="true"
                :flat="true"
                :options="filterOptions.bolgeler"
              />
            </div>

            <!-- Ratings -->
            <div class="ratings">
              <h6 class="filter-title">Beğenilere</h6>
              <div v-for="rating in filterOptions.ratings" :key="rating.rating" class="ratings-list">
                <b-link>
                  <div class="d-flex">
                    <feather-icon
                      v-for="star in 5"
                      :key="star"
                      icon="StarIcon"
                      size="17"
                      :class="[{ 'fill-current': star <= rating.rating }, star <= rating.rating ? 'text-warning' : 'text-muted']"
                    />
                    <span class="ml-25">&amp; up</span>
                  </div>
                </b-link>
                <div class="stars-received">
                  <span>{{ rating.count }}</span>
                </div>
              </div>
            </div>
            <div>
              <b-button squared block variant="primary" type="submit">
                <feather-icon icon="SearchIcon" class="text-muted" />
                Filtrele
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  components: {
    // 3rd Party
    VueSlider,
    Treeselect,
  },
  props: {
    filtrele: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    normalizer(node) {
      return {
        id: node.key,
        label: node.name,
        children: node.subOptions,
      };
    },
  }),
};
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss">
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.vue-treeselect__control {
  border: 1px solid #d8d6dd;
  border-radius: 0px !important;
  height: 38px;
}
</style>
